import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import localforage from "localforage";
import "../css/bmcInterventionDashboard.css";
import Dashboard from "./Dashboard";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";

const BmcInterventionDashboard = () => {
  const [roles, setRoles] = useState([]);
  const [pcs, setPcs] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [selectedPC, setSelectedPC] = useState(null);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [wards, setWards] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [interventionTypes, setInterventionTypes] = useState([]);
  const [selectedInterventionType, setSelectedInterventionType] =
    useState(null);
  const [interventionActions, setInterventionActions] = useState([]);
  const [selectedInterventionAction, setSelectedInterventionAction] =
    useState(null);
  const [interventionCounts, setInterventionCounts] = useState({});
  const [interventionData, setInterventionData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Items per page

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesFromLocalForage = (await localforage.getItem("role")) || [];
        setRoles(rolesFromLocalForage);
      } catch (error) {
        console.error("Error fetching roles from localForage:", error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchInterventionData = async () => {
      try {
        // Get user ID from localforage
        const userId = await localforage.getItem("ID");
        if (!userId) {
          console.error("User ID not found in localforage.");
          return;
        }
        const token = await localforage.getItem("token");

        const response = await axios.get(
          `${API_URL}/bmc/get-intervention-data/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Fetch pcs and constituencies data
        const fetchedPcs = response.data.map((data) => data.pc).filter(Boolean); // Extract pcs from the response
        const fetchedConstituencies = response.data
          .map((data) => data.constituency)
          .filter(Boolean); // Extract constituencies from the response

        // Remove duplicates by converting them to a Set and then back to an array
        const uniquePcs = [...new Set(fetchedPcs)];
        const uniqueConstituencies = [...new Set(fetchedConstituencies)];

        // Set pcs and constituencies state
        setPcs(uniquePcs.map((pc) => ({ value: pc, label: pc })));
        setConstituencies(
          uniqueConstituencies.map((constituency) => ({
            value: constituency,
            label: constituency,
          }))
        );

        setInterventionData(response.data); // Set the intervention data
      } catch (error) {
        console.error("Error fetching intervention data:", error);
      }
    };

    if (roles.length > 0) fetchInterventionData();
  }, [
    selectedConstituency,
    selectedPC,
    selectedInterventionType,
    selectedInterventionAction,
    roles,
    selectedWard,
  ]);

  useEffect(() => {
    const fetchWards = async () => {
      if (!selectedConstituency) return;

      try {
        const token = await localforage.getItem("token");
        const response = await axios.get(
          `${API_URL}/bmc/get-wards/${selectedConstituency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setWards(
          response.data.wards.map((ward) => ({ value: ward, label: ward }))
        );
      } catch (error) {
        console.error("Error fetching wards:", error);
        setWards([]);
      }
    };

    fetchWards();
  }, [selectedConstituency]);

  useEffect(() => {
    const fetchInterventionTypesAndActions = async () => {
      try {
        const types = [
          "Political",
          "Party / Organizational",
          "Government",
          "Alliance",
          "Leader Activation",
        ];
        const actions = ["Solved", "Not Solved", "Action Taken"];
        setInterventionTypes(types);
        setInterventionActions(actions);
      } catch (error) {
        console.error("Error fetching intervention types and actions:", error);
      }
    };

    fetchInterventionTypesAndActions();
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      const token = await localforage.getItem("token");
      try {
        // Construct the API endpoint with the filters applied
        const params = {
          pc: selectedPC, // Filter by PC
          constituency: selectedConstituency, // Filter by Constituency
          ward: selectedWard, // Filter by Ward
          interventionType: selectedInterventionType, // Filter by Intervention Type
          interventionAction: selectedInterventionAction, // Filter by Intervention Action
        };

        const response = await axios.get(`${API_URL}/bmc/interventions/counts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params, // Pass the filters as query parameters
        });

        setInterventionCounts(response.data);
      } catch (error) {
        console.error("Error fetching intervention counts:", error);
      }
    };

    // Fetch the counts when any of the filters or roles change
    if (roles.length > 0) fetchCounts();
  }, [
    selectedPC, // PC filter
    selectedConstituency, // Constituency filter
    selectedWard, // Ward filter
    selectedInterventionType, // Intervention Type filter
    selectedInterventionAction, // Intervention Action filter
    roles, // Trigger fetch when roles change
  ]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "250px", // Set the width of the select box
      marginBottom: "10px",
      border: "2px solid black",
      color: "black",
    }),
  };

  const handleInterventionActionChange = async (id, newValue) => {
    try {
      // Update intervention action in the backend
      await axios.put(`${API_URL}/bmc/update-intervention-action/${id}`, {
        interventionAction: newValue,
      });

      // Update the intervention action in the state
      setInterventionData((prevData) =>
        prevData.map((data) =>
          data._id === id ? { ...data, interventionAction: newValue } : data
        )
      );
    } catch (error) {
      console.error("Error updating intervention action:", error);
    }
  };

  const csvHeaders = [
    { label: "PC", key: "pc" },
    { label: "Constituency", key: "constituency" },
    { label: "Ward", key: "ward" },
    { label: "Category", key: "category" },
    { label: "Department", key: "department" },
    { label: "Intervention Type", key: "interventionType" },
    { label: "Intervention Issues", key: "interventionIssues" },
    {
      label: "Intervention Issue Brief",
      key: "interventionIssueBrief",
    },
    { label: "Suggested Actionable", key: "suggestedActionable" },
    { label: "Facilitator Name", key: "facilitatorName" },
    { label: "Facilitator Number", key: "facilitatorNumber" },
    { label: "Leader Name", key: "leaderName" },
    { label: "Leader Number", key: "leaderNumber" },
    { label: "Created On", key: "createdAt" },
    { label: "Intervention Action", key: "interventionAction" },
  ];

  const toggleRowExpansion = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const truncateText = (text, id) => {
    const words = text.split(" ");
    if (expandedRows[id] || words.length <= 5) {
      return (
        <>
          {text}{" "}
          {words.length > 5 && (
            <span
              className="toggle-text"
              onClick={() => toggleRowExpansion(id)}
            >
              Show Less
            </span>
          )}
        </>
      );
    }
    return (
      <>
        {words.slice(0, 5).join(" ")}...{" "}
        <span className="toggle-text" onClick={() => toggleRowExpansion(id)}>
          Show More
        </span>
      </>
    );
  };

  const filterData = () => {
    return interventionData.filter((data) => {
      const isPCMatch = selectedPC ? data.pc === selectedPC : true;
      const isConstituencyMatch = selectedConstituency
        ? data.constituency === selectedConstituency
        : true;
      const isWardMatch = selectedWard ? data.ward === selectedWard : true;
      const isInterventionTypeMatch = selectedInterventionType
        ? data.interventionType === selectedInterventionType
        : true;
      const isInterventionActionMatch = selectedInterventionAction
        ? data.interventionAction === selectedInterventionAction
        : true;

      return (
        isPCMatch &&
        isConstituencyMatch &&
        isWardMatch &&
        isInterventionTypeMatch &&
        isInterventionActionMatch
      );
    });
  };

  const filteredData = filterData();
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected); // Update current page
  };

  return (
    <>
      <Dashboard />
      <div className="intervention-data-component">
        <h2>BMC Intervention Dashboard</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {!errorMessage && (
          <div className="filter-controls">
            <Select
              id="pc"
              options={pcs}
              placeholder="Select PC"
              value={pcs.find((pc) => pc.value === selectedPC) || null}
              onChange={(option) => setSelectedPC(option?.value || null)}
              styles={customStyles}
              isClearable
            />

            <Select
              id="constituency"
              options={constituencies}
              styles={customStyles}
              placeholder="Select Constituency"
              value={
                constituencies.find((c) => c.value === selectedConstituency) ||
                null
              }
              onChange={(option) => {
                setSelectedConstituency(option?.value || null);
                setSelectedWard(null);
              }}
              isClearable
            />

            <Select
              id="ward"
              options={wards}
              styles={customStyles}
              placeholder="Select Ward"
              value={wards.find((ward) => ward.value === selectedWard) || null}
              onChange={(option) => setSelectedWard(option?.value || null)}
              isClearable
            />

            <Select
              id="interventionType"
              styles={customStyles}
              placeholder="Select Intervention Type"
              options={interventionTypes.map((type) => ({
                value: type,
                label: type,
              }))}
              value={
                selectedInterventionType
                  ? {
                      value: selectedInterventionType,
                      label: selectedInterventionType,
                    }
                  : null
              }
              onChange={(option) =>
                setSelectedInterventionType(option?.value || null)
              }
              isClearable
            />

            <Select
              id="interventionAction"
              styles={customStyles}
              placeholder="Select Intervention Action"
              options={interventionActions.map((action) => ({
                value: action,
                label: action,
              }))}
              value={
                selectedInterventionAction
                  ? {
                      value: selectedInterventionAction,
                      label: selectedInterventionAction,
                    }
                  : null
              }
              onChange={(option) =>
                setSelectedInterventionAction(option?.value || null)
              }
              isClearable
            />
          </div>
        )}
        <div className="intervention-counts">
          <h2>Intervention Counts</h2>
          <div className="counts-grid">
            {interventionCounts.typeCounts && (
              <div className="count-card total-count">
                <h3>Total</h3>
                <p>
                  {Object.values(interventionCounts.typeCounts).reduce(
                    (total, count) => total + count,
                    0
                  )}
                </p>
              </div>
            )}

            {/* Display counts for each intervention type */}
            {Object.entries(interventionCounts.typeCounts || {}).map(
              ([type, count]) => (
                <div key={type} className="count-card">
                  <h3>{type}</h3>
                  <p>{count}</p>
                </div>
              )
            )}
            <CSVLink
              data={interventionData}
              headers={csvHeaders}
              filename={`Intervention_Data_${[
                selectedConstituency,
                selectedInterventionType,
                selectedPC,
                selectedWard,
                selectedInterventionAction,
              ]
                .filter(Boolean)
                .join("_")}`}
            >
              <FontAwesomeIcon
                icon={faFileCsv}
                className="font-pdf"
                size="2x"
                style={{ marginLeft: "20px", color: "#325c23" }}
              />
            </CSVLink>
          </div>
        </div>
        <div className="intervention-table">
          <h2>Intervention Data</h2>
          <table>
            <thead>
              <tr>
                <th>PC</th>
                <th>Constituency</th>
                <th>Ward</th>
                <th>Intervention Type</th>
                <th>Intervention Issues</th>
                <th>Intervention Issue Brief</th>
                <th>Department</th>
                <th>Suggested Actionable</th>
                <th>Facilitator Number</th>
                <th>Facilitator Name</th>
                <th>Leader Number</th>
                <th>Leader Name</th>
                <th>Category</th>
                <th>Date</th>
                <th>Intervention Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((data, index) => {
                const getBackgroundColor = (action) => {
                  switch (action) {
                    case "Solved":
                      return "#b6d7a8";
                    case "Not Solved":
                      return "#ea9999";
                    case "Action Taken":
                      return "#ffe599";
                    default:
                      return "transparent";
                  }
                };
                return (
                  <tr key={index}>
                    <td>{data.pc}</td>
                    <td>{data.constituency}</td>
                    <td>{data.ward}</td>
                    <td>{data.interventionType}</td>
                    <td>{data.interventionIssues}</td>
                    <td>
                      {truncateText(data.interventionIssueBrief, data._id)}
                    </td>
                    <td>{data.department || "-"}</td>
                    <td>{data.suggestedActionable || "-"}</td>
                    <td>{data.facilitatorNumber || "-"}</td>
                    <td>{data.facilitatorName || "-"}</td>
                    <td>{data.leaderNumber || "-"}</td>
                    <td>{data.leaderName || "-"}</td>
                    <td>{data.category}</td>
                    <td>
                      {new Date(data.createdAt).toLocaleDateString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </td>

                    <td
                      style={{
                        maxWidth: "140px",
                        backgroundColor: getBackgroundColor(
                          data.interventionAction || "Not Solved"
                        ),
                      }}
                    >
                      <select
                        value={data.interventionAction || "Not Solved"}
                        style={{ width: "140px" }}
                        onChange={(e) =>
                          handleInterventionActionChange(
                            data._id,
                            e.target.value
                          )
                        }
                      >
                        <option value="Not Solved">Not Solved</option>
                        <option value="Solved">Solved</option>
                        <option value="Action Taken">Action Taken</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
};

export default BmcInterventionDashboard;
